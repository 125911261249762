
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


























































































































































































































.bold {
  font-weight: 600;
}

h1.h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 100% */
  text-transform: uppercase;
}

.headerBorne {
  font-weight: 100;
}

.head_info {
  width: 50%;

}

.ean__item__address {
  line-height: 1;
}

.action_add {
  padding-top:5em;
}

.action_icon {
  fill:$c-white;
  width: 1.2rem;
  height: 1.2rem;
  margin-left:1.8rem;
  vertical-align: middle;
  transform: rotate(-90deg);
}

button{
  border: none;
  border-radius: 50px;
  background-color: $c-orange;
  color: $c-white;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px 8px 12px;
}

.error {
  background-color: $c-bg-error;
  padding: 25px;
  margin-bottom: 30px;
  color: $c-red;
  border-radius: 20px;

  ::v-deep {
    .close-link {
      float: right;
      cursor: pointer;
    }

    svg {
      fill: $c-red;
    }
  }
}
.action_add {
  margin-top : 4rem;
  padding: 1rem 2rem;
  background-color: $c-orange;
  border: 0;
  border-radius: 99em;
  float:left;
}

.newBorne {
  color:white;
  text-decoration: none;
}

.alert-success {
  margin-top: 30px;
  color:#3E7A6B;
  background-color: #F5F8F7;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius:0;

  .icon {
    fill:#3E7A6B;
  }
}

.alert-danger {
  margin-top: 30px;
  color:#D00404;
  background-color: #FEFBFA;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius:0;

  .icon {
    fill:#D00404;
  }

}


