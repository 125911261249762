
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













































































































































































.headerBorne {
  padding: 1em;
}

.lineBorne {
  padding-bottom: 2.5em;
}

.tableauChargingTerminals {
  background: #ffffff;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

::v-deep {
  .colTab {
    width: 21%;
    padding: 0 2px 0 2px;
  }

  .statut {
    display: flex;
  }

  .action {
    width: 15%;
  }

  @media screen and (max-width: 560px) {
    .numero_serie {
      display: none;
    }

    .colTab {
      width: 30%;
    }

    .action {
      width: 10%;
    }
  }
}

hr.separateur {
  border-bottom: 1px solid #e0e0e0;
}
.zoneTable {
  padding-bottom: 6rem;
}

.colTab {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #c1c1c1;

  a {
    color: #c1c1c1;
    cursor: pointer;
  }
}

.icon_tri_table {
  width: 1rem;
  vertical-align: middle;
  fill: #c1c1c1;
}

div.headerBorne div {
  font-size: 1.2rem;
}
