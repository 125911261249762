
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































.modal-content {
  text-align: center;
  .h3 {
    margin: 2rem 0;
    color: $c-black;
    font-size: 18px;
  }

  .modal-content__button {
    ::v-deep {
      button.cancel-delete {
        margin: 2rem 0 1.5rem 3rem;
        padding: 1rem 2rem;
        background-color: $c-white;
        border: solid 1px $c-orange;
        float: left;
        color: $c-orange;
        text-transform: none;
        @media only screen and (max-width: 768px) {
          margin-left: 0;
          padding-left: 0;
        }
      }
      .action__icon {
        fill: $c-orange;
      }

      button.cancel-delete:hover {
        background-color: $c-orange;
        color: $c-white;
        .action__icon {
          fill: $c-white;
        }
      }

      .confirm-delete {
        //margin-top: 4rem;
        margin: 2rem 0rem 0 3rem;
        padding: 1rem 2rem;
        border: 0;
        float: right;
        text-transform: none;
        .action__icon {
          fill: $c-white;
        }
        @media only screen and (max-width: 768px) {
          margin-left: 1rem;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}
