
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































































































































































h4 {
  padding-top:1em;
}

.section {
  width: 100%;
}

.closeView {
  text-align:right;
}

/*
.marque, .modele, .numero_serie, .puissance, .dateMEF {
  width: 17%;
}
 */

.contentTerminalsLine {
  margin: 2rem;
  padding-top: 0.5rem;
}

.ean__item__pin {
  // prettier-ignore
  @include fluid(width, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(height, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(margin-right, (xxs: 10px, xxl: 20px));

  flex-shrink: 0;
  fill: $c-main-primary;
}

.icon_action_table {
  fill: $c-main-primary;
  width: 20px;
  height: 20px;
  margin-left: 1rem;
}

.icon_trash {
  cursor: pointer;
}

.accordion-item__header__icon {
  fill: $c-main-primary;
  flex-shrink: 0;
  overflow: visible;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1.5rem;
  margin-right: 1rem;
  transform: rotate(-180deg);
}

.colData {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  word-wrap: break-word;
  color:#333333;
}

.label_switcher {
  color:#333333;
}

.contentBorne{
  overflow: hidden;
  line-height: 1.6;
}

.showHideInfo, .masquerDetail{
  text-decoration: underline;
  cursor:pointer;
}

.lineBorne {
  padding-bottom: 1em;
}

::v-deep {
  .modal-overlay .modal {
    border-radius: 30px 30px 30px 30px;
    max-width: 50%;

    @media only screen and (max-width: 768px) {
      max-width: 90%;
      padding: 0;
    }

    .modal-header .close {
      display: block;
      border:none;
      top:1em;


      .icon{
        fill:$c-black;
      }
    }

    .modal-body {
      margin-top:2.5em;
      margin-bottom: 5em;

      @media only screen and (max-width: 768px) {
        margin-top:0.5em;
        margin-bottom: 1em;
      }
    }

  }


}
